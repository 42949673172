import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Logo from '../components/logo'
import Topo from '../components/topo'

const Loading = () => (
  <div className="loader">
    <Topo />
    <Row center="xs" middle="xs">
      <Col xs={10}>
        <Logo />
        <h1 className="load-heading">LOADING</h1>
        <span>Thanks for your patience.</span>
      </Col>
    </Row>
  </div>
)

export default Loading
