import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { Link } from 'gatsby';
import ScrollMagic from 'scrollmagic/scrollmagic/uncompressed/ScrollMagic';
import Layout from '../components/layout';
import { Design, Identity, Web, Print, Direction } from '../images/service-icons';
import Loading from '../components/loading';
import 'scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'gsap/CSSPlugin';
import CTA from '../components/button';
import * as Fancy from '../utils/fancy';

// image imports
import sky from '../images/sky.png';
import range1 from '../images/1.png';
import range2 from '../images/2.png';
import range3 from '../images/3.png';
import range4 from '../images/4.png';
import range5 from '../images/5.png';
import cloud1 from '../images/cloud1.png';
import cloud2 from '../images/cloud2.png';
import cloud3 from '../images/cloud3.png';
import profile from '../images/trevor-circle.png';
import { TweenMax } from 'gsap';

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      controller: undefined,
      tween: undefined,
      scene: undefined,
    };
  }

  componentDidMount() {
    if (document.body.classList.contains('loading') == true) {
      setTimeout(() => this.imgInterval(), 2000);
    }

    const hero = document.getElementById('hero');
    const dur = hero.offsetHeight;
    const ranges = document.getElementsByClassName('range-img');
    const skyBG = ranges[0];
    const range1 = ranges[1];
    const range2 = ranges[2];
    const range3 = ranges[3];
    const range4 = ranges[4];
    const range5 = ranges[5];
    const clouds = document.getElementsByClassName('cloud');
    const cloud1 = clouds[0];
    const cloud2 = clouds[1];
    const cloud3 = clouds[2];
    const heading = document.getElementsByClassName('hero-text')[0];
    const loader = document.getElementsByClassName('loader')[0];

    const controller = new ScrollMagic.Controller();

    const tween = new TimelineMax().add([
      TweenMax.to(skyBG, 1, { yPercent: 50, force3D: true, ease: Power0.easeNone }),
      TweenMax.to(range1, 1, { yPercent: 50, force3D: true, ease: Power0.easeNone }), // Small BG Mountains
      TweenMax.to(range2, 1, { yPercent: 45, force3D: true, ease: Power0.easeNone }), // Big Mountains
      TweenMax.to(cloud1, 1, { yPercent: 40, force3D: true, ease: Power0.easeNone }),
      TweenMax.to(range3, 1, { yPercent: 30, force3D: true, ease: Power0.easeNone }),
      TweenMax.to(cloud2, 1, { yPercent: 25, force3D: true, ease: Power0.easeNone }),
      TweenMax.to(range4, 1, { yPercent: 18, force3D: true, ease: Power0.easeNone }),
      TweenMax.to(cloud3, 1, { yPercent: 5, force3D: true, ease: Power0.easeNone }),
      TweenMax.to(heading, 1, { y: -dur / 5, scale: 0.8 }),
    ]);

    const scene = new ScrollMagic.Scene({
      triggerElement: '.hero-mountain',
      triggerHook: 0.01,
      duration: dur,
    })
      .setTween(tween)
      .addTo(controller);
    // .addIndicators()

    this.setState({
      controller,
      tween,
      scene,
    });
  }

  componentWillUnmount() {
    this.state.controller.destroy(true);
    this.state.controller = null;
    this.state.tween = null;
    this.state.scene = null;
  }

  imagesLoaded = () => {
    const imgElements = document.querySelectorAll('img');
    for (let i = 0; i < imgElements.length; i += 1) {
      const img = imgElements[i];
      if (!img.complete) {
        return false;
      }
    }
    return true;
  };

  imgInterval = () => {
    var listen = setInterval(() => {
      if (this.imagesLoaded() === true) {
        clearInterval(listen);
        document.body.classList.remove('loading');
        document.body.classList.add('loaded');
        Fancy.magic();
      }
    }, 200);
  };

  render() {
    return (
      <Layout>
        <div className="page-container">
          <Loading />
          <section id="hero" className="full-section">
            <div className="hero-mountain">
              <img src={sky} className="range-img" alt="" />
              <img src={range1} className="range-img" alt="" />
              <img src={range2} className="range-img" alt="" />
              <img src={cloud1} className="cloud cloud1" alt="" />
              <img src={range3} className="range-img" alt="" />
              <img src={cloud2} className="cloud cloud2" alt="" />
              <img src={range4} className="range-img" alt="" />
              <img src={cloud3} className="cloud cloud3" alt="" />
              <div className="hero-text">
                <h1 className="big-heading scroll">
                  <span className="hero-letter s">S</span>
                  <span className="hero-letter p">P</span>
                  <span className="hero-letter i">I</span>
                  <span className="hero-letter n">N</span>
                  <span className="hero-letter t">T</span>
                  <span className="hero-letter u">U</span>
                  <span className="hero-letter r">R</span>
                  <span className="hero-letter n">N</span>
                  <span className="hero-letter i">I</span>
                  <span className="hero-letter x">X</span>
                </h1>
                <span className="subhead">Design + Development</span>
              </div>
              <img src={range5} className="range-img" alt="" />
            </div>
          </section>

          <section id="about" className="full-section">
            <Row middle="xs">
              <Col xs={10} xsOffset={1} smOffset={0} sm={3} md={4}>
                <Row center="xs">
                  <Col xs={12}>
                    <img src={profile} className="profile" width="350" alt="" />
                  </Col>
                  <Col xs={12}>
                    <ul className="social-links">
                      <li>
                        <a href="https://twitter.com/SpinturnixInc" target="_blank">
                          Twitter
                        </a>
                      </li>
                      <li>
                        <a href="https://www.linkedin.com/in/trevor-g/" target="_blank">
                          Linkedin
                        </a>
                      </li>
                      <li>
                        <a href="mailto:trevor@spinturnix.com" target="_blank">
                          Email
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Col>
              <Col xs={10} xsOffset={1} smOffset={1} sm={8} mdOffset={1} md={7}>
                <h2 className="section-heading scroll">Hey There.</h2>
                <p>My name is Trevor.</p>
                <p>I’m a designer, developer and creator of all things visual.</p>
                <p>
                  Before starting Spinturnix, I was knee-deep in the world of professional advertising. I learned a
                  great deal and made enough money, but I was deeply unfulfilled.
                </p>
                <p>
                  Now free of sales jargon, pressing quotas and corner-cutting, I’m providing my clients with quality
                  work their brands need to thrive.
                </p>
                <CTA text="Start A Project" link="/#start" />
              </Col>
            </Row>
          </section>
          <section id="services" className="full-section">
            <Row middle="xs">
              <Col xs={10} xsOffset={1} md={6} mdOffset={0}>
                <h2 className="section-heading">Services</h2>
                <p>
                  Good design makes a brand memorable, <i>great</i> design makes it meaningful. Whether it's a website,
                  logo, business card or email, people quickly connect with what catches their attention.
                </p>
                <p>This is where my services come into focus.</p>
                <div className="hide-sm">
                  <CTA text="Start A Project" link="/#start" />
                </div>
              </Col>
              <Col xs={10} xsOffset={1} md={6} mdOffset={0}>
                <div className="service-list">
                  <Link to="/services/#web">
                    <Row middle="xs" className="service-item">
                      <Col xs={2} className="center-xs">
                        <Web />
                      </Col>
                      <Col xs={10}>
                        <h3>Web Design & Development</h3>
                      </Col>
                    </Row>
                  </Link>
                  <Link to="/services/#identity">
                    <Row middle="xs" className="service-item">
                      <Col xs={2} className="center-xs">
                        <Identity />
                      </Col>
                      <Col xs={10}>
                        <h3>Branding & Visual identity</h3>
                      </Col>
                    </Row>
                  </Link>
                  <Link to="/services/#direction">
                    <Row middle="xs" className="service-item">
                      <Col xs={2} className="center-xs">
                        <Direction />
                      </Col>
                      <Col xs={10}>
                        <h3>Creative Direction</h3>
                      </Col>
                    </Row>
                  </Link>
                  <Link to="/services/#design">
                    <Row middle="xs" className="service-item">
                      <Col xs={2} className="center-xs">
                        <Print />
                      </Col>
                      <Col xs={10}>
                        <h3>Collateral & Print Design</h3>
                      </Col>
                    </Row>
                  </Link>
                </div>
                <div className="show-sm">
                  <CTA text="Start A Project" link="/#start" />
                </div>
              </Col>
            </Row>
          </section>
        </div>
      </Layout>
    );
  }
}
